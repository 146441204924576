import { getUserDecision, Cookie } from "@devowl-wp/cookie-consent-web-client";
function useBlockerStateContextCallbacks(opts) {
  return {
    onUnblock: (_ref, event) => {
      let {
        onApplyConsent,
        groups,
        essentialGroup,
        blocker,
        recorder,
        isGcm,
        gcmConsent: blockerGcmConsent
      } = _ref;
      const {
        decisionCookieName,
        tcfCookieName,
        gcmCookieName
      } = opts;
      const {
        services,
        visualThumbnail,
        id: blockerId
      } = blocker;
      const currentDecision = getUserDecision(decisionCookieName);
      const [essentialGroupObj] = groups.filter(_ref2 => {
        let {
          slug
        } = _ref2;
        return slug === essentialGroup;
      });

      // Prepare new consent
      const newConsent = currentDecision === false ?
      // The website owner uses a banner without overlay and content gets unblocked
      // We need to make sure, that also essential cookies got accepted
      {
        [essentialGroupObj.id]: essentialGroupObj.items.map(_ref3 => {
          let {
            id
          } = _ref3;
          return id;
        })
      } : currentDecision.consent;
      for (const {
        id: groupId,
        items
      } of groups) {
        for (const {
          id: cookieId
        } of items) {
          if (services.indexOf(cookieId) > -1) {
            var _newConsent$groupId;
            // Avoid duplicates
            if (((_newConsent$groupId = newConsent[groupId]) === null || _newConsent$groupId === void 0 ? void 0 : _newConsent$groupId.indexOf(cookieId)) > -1) {
              continue;
            }
            newConsent[groupId] = newConsent[groupId] || [];
            newConsent[groupId].push(cookieId);
          }
        }
      }

      // Keep TCF string as it is not touched for a content blocker
      const tcfString = Cookie.get(tcfCookieName);

      // Create modified GCM consent and keep previous consents intact and add new consent types
      let gcmConsent;
      /* Silence is golden... Until you have PRO Version! */

      onApplyConsent({
        consent: newConsent,
        gcmConsent,
        buttonClicked: "unblock",
        blocker: blockerId,
        blockerThumbnail: visualThumbnail !== null && visualThumbnail !== void 0 && visualThumbnail.embedId ? `${visualThumbnail.embedId}-${visualThumbnail.fileMd5}` : undefined,
        tcfString,
        recorderJsonString: recorder ? JSON.stringify(recorder.createReplay()) : undefined,
        uiView: "initial",
        event
      });
    }
  };
}
export { useBlockerStateContextCallbacks };